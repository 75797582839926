<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Select all practical applications of TLC from the list below:</p>

      <div v-for="option in options" :key="option.value" class="mb-0 pl-6">
        <v-checkbox
          v-model="inputs.applicationsTLC"
          class="d-inline-block my-0 py-1"
          :value="option.value"
          :dense="true"
          hide-details="true"
          :ripple="false"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput.vue';

export default {
  name: 'uOttawa1321Prelab1Q6',
  components: {
    DragToRankInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        applicationsTLC: [],
      },
      options: [
        {text: 'Identifying a compound', value: 'identification'},
        {text: 'Performing a quantitative analysis of a reaction', value: 'quantitativeAnalysis'},
        {text: 'Determining if a reaction is finished', value: 'reactionCompletion'},
        {text: 'Verifying the purity of a compound', value: 'verifyPurity'},
        {text: 'All of the above', value: 'all'},
      ],
    };
  },
};
</script>
